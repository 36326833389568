body,
#container {
  background: #222;
  padding-top: 20px;
  padding-bottom: 20px;
}

.inner {
  background: #444;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 740px;
  max-width: 900px;
  margin: 0 auto;
}

.App-logo {
  width: 120px;
  height: 120px;
}

.logo-with-name {
  font-size: 2.8em !important;
}

.listings {
  font-size: 1.4em !important;
}

.rpgui-button p {
  margin: 0;
}

.overflow-x-auto {
  overflow-x: auto;
}

img {
  width: 50%;
}

.img-title {
  width: 50%;
  font-size: 1.25em;
  line-height: 1.75em;
  padding: 12px;
  color: #fff;
  text-shadow: -2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000;
}

.skellies {
    width: 25%;
}

.token {
    width: 50% !important;
}

.section-title {
    display: flex;
    justify-content: start;
    text-decoration: none !important;
    align-items: end;
}

/* marquee scroll */

.marquee {
    height: 50px;
    overflow: hidden;
    position: relative;
    /* background: #fefefe; */
    color: #333;
    border: 1px solid #4a4a4a;
}

.marquee p {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: scroll-left 2s linear infinite;
    -webkit-animation: scroll-left 2s linear infinite;
    animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

/*Anything outside of media queries is for MOBILE
  This is Mobile first approach.
*/

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .logo {
    display: flex;
    justify-content: end;
  }

  .logo-with-name {
    font-size: 5.5em;
  }

  img {
    width: 100%;
  }

  .skellies {
    width: 25%;
}

  .img-title {
    width: 100%;
  }

  .token {
      width: 100% !important;
  }
  .section-title {
    display: flex;
    justify-content: end;
    text-decoration: none !important;
    align-items: end;
}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .logo {
    display: flex;
    justify-content: end;
  }

  .logo-with-name {
    font-size: 5.5em;
  }

  img {
    width: 100%;
  }

  .img-title {
    width: 100%;
  }

  .skellies {
      width: 50%;
  }
  .token {
    width: 100% !important;
}
.section-title {
    display: flex;
    justify-content: end;
    text-decoration: none !important;
    align-items: end;
}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .logo {
    display: flex;
    justify-content: end;
  }

  .logo-with-name {
    font-size: 5.5em;
  }

  img {
    width: 100%;
  }

  .img-title {
    width: 100%;
  }
  .token {
    width: 100% !important;
}
.section-title {
    display: flex;
    justify-content: end;
    text-decoration: none !important;
    align-items: end;
}
}
